import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _map from 'lodash/map';
import { isMobile } from 'react-device-detect';
import { Link } from 'gatsby';

import BlogCardInfo from '../../components/blogInfoCard';

import { sectionBasic, latestPosts } from '../../scss/content.module.scss';
import { EMPTY_ARRAY } from '../../constants/base';
import {
  responsiveCardContainer,
  customCardStyle,
  exploreBlogs,
} from './LatestPosts.module.scss';
import ButtonPrimary from '../../components/ButtonPrimary';

// responsible for loading all the contents
function LatestPosts({ contents }) {
  return (
    <section className={cx(sectionBasic, latestPosts)}>
      <h2 className="text-brand-underline">Have you read?</h2>
      <div
        className={cx(
          'container',
          'flex',
          'flex-center',
          responsiveCardContainer
        )}
      >
        {_map(contents, (content, index) => {
          const { categories, _createdAt, slug, title } = content;

          return (
            <BlogCardInfo
              categories={categories}
              _createdAt={_createdAt}
              slug={slug}
              title={title}
              key={index}
              customLinkCardClass={customCardStyle}
              showTooltip={isMobile}
            />
          );
        })}

        <Link to="/blogs">
          <ButtonPrimary customClassNames={exploreBlogs}>
            Explore Sumit&apos;s Blogs
          </ButtonPrimary>
        </Link>
      </div>
    </section>
  );
}

export default LatestPosts;

LatestPosts.propTypes = {
  contents: PropTypes.array,
};

LatestPosts.defaultProps = {
  contents: EMPTY_ARRAY,
};
