/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import cx from 'classnames';
import { aboutPage, aboutPara } from './About.module.scss';

function About() {
  return (
    <section className={cx('light-bg', aboutPage)}>
      <div
        className={cx(
          'flex',
          'flex-space-between',
          'container',
          'layered-content'
        )}
      >
        <h2 className={cx('weigthed-text', 'text-brand-underline')}>
          About This Page
        </h2>
        <div className={aboutPara}>
          <p>
            Hey there! Just like you, I'm all about picking up new things in our
            day-to-day. Here, I'll be dishing out articles on what I've learned
            each week. Trust me, it's all about making life a tad easier,
            happier, and a whole lot more productive. Stick around for some good
            vibes and handy tips! 🌟
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
