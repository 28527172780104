import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import LatestPosts from '../page-components/LatestPosts';
import HeroBanner from '../components/HeroBanner';
import heroImage from '../images/Sumit_Pal_web_image.png';
import About from '../components/About';

const site = 'sumitpal.in';
const title = 'Sumit Pal | Web Developer | content creator';
const description =
  'Welcome to Sumit`s blog, get weekly contents on tech, engineering, investments, time management, and productivity';
const seo = {
  title,
  description,
  canonical: site,
  openGraph: {
    url: site,
    title,
    description,
    images: [
      {
        url: heroImage,
        width: 800,
        height: 800,
        alt: title,
      },
    ],
    site_name: site,
  },
};

function Home({ data }) {
  const cmsContents = _get(data, 'allSanityPost.nodes', []);

  return (
    <Layout seoData={seo}>
      <HeroBanner />
      <About />
      <LatestPosts contents={cmsContents} />
    </Layout>
  );
}

export default Home;

Home.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allSanityPost(sort: { fields: [_createdAt], order: DESC }, limit: 3) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        categories {
          title
          colorHexCode
        }
        _createdAt
      }
    }
  }
`;
